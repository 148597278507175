import React, { PropsWithChildren, useEffect, useState } from "react"
import { faBell } from "@fortawesome/pro-regular-svg-icons"
import { Icon } from "../icon/icon"
import { GlobalAlertsPopUpModal } from "./globalAlertModal"
import { AlertQuery } from "../../pages/api/__generated__/alerts"
import {
  StyledGlobalAlertBtn,
  StyledGlobalAlertWrapper,
  StyledRedBubble,
} from "./StyledGlobalAlertTrigger"

export type AlertProps = AlertQuery

export const GlobalAlertTrigger: React.FC<PropsWithChildren<AlertProps>> = ({ entries }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAlertViewed, setIsAlertViewed] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedData = sessionStorage.getItem("isAlertViewed")
      if (storedData) {
        const { timestamp } = JSON.parse(storedData)
        const now = new Date().getTime()
        const threeDaysInMs = 259200 // 3 days

        if (now - timestamp < threeDaysInMs) {
          setIsAlertViewed(true)
        } else {
          sessionStorage.removeItem("isAlertViewed")
        }
      }
    }
  }, [])

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsModalOpen(true)
    setIsAlertViewed(true)

    if (typeof window !== "undefined") {
      const data = {
        timestamp: new Date().getTime(),
      };
      sessionStorage.setItem("isAlertViewed", JSON.stringify(data))
    }
  }

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {isModalOpen && (
        <GlobalAlertsPopUpModal
          title={`Latest alerts`}
          popUpStatus={true}
          onClose={closeModal}
          entries={entries}
        />
      )}
      <StyledGlobalAlertWrapper>
        <StyledGlobalAlertBtn onClick={handleButtonClick}>
          <Icon icon={faBell} />
          {!isAlertViewed && <StyledRedBubble />}
        </StyledGlobalAlertBtn>
      </StyledGlobalAlertWrapper>
    </>
  )
}
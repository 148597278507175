import { Button, IconButton } from "@material-ui/core";
import styled from "styled-components";

export const StyledGlobalButton = styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(12)};
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(15)};
  color: ${props => props.theme.palette.primary.dark};
  border: none;
  background-color: #FFF7E7;
  position: relative;
  border-radius: ${props => props.theme.typography.pxToRem(100)};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

  &::after {
    content: '';
    background: url(/images/arrow-up-right-from-square-solid.svg) 0 0 no-repeat;
    background-size: contain;
    width: ${props => props.theme.typography.pxToRem(10)};
    height: ${props => props.theme.typography.pxToRem(10)};
    margin-left: ${props => props.theme.typography.pxToRem(5)};
  }

  &:hover {
    background-color: #FFB81C;
    border: none;
    color: ${props => props.theme.palette.primary.dark};
  }
    
  .MuiButton-startIcon svg {
    position: relative;
    top: -1px;
    font-size: 1.2em;
    color: ${props => props.theme.palette.primary.dark};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 15px;
    padding: 0 15px;
    height: 40px;

    .MuiButton-startIcon svg {
      position: relative;
      top: -1px;
      font-size: 1em;
    }
  }

  &::after {
    width: 15px;
    height: 15px;
  }
`

export const QEWrapper = styled.div`
  //
`

export const StyledPopUp = styled.div<{ isPopupVisible: boolean }>`
  position: fixed;
  z-index: 1200;
  bottom: ${props => props.theme.typography.pxToRem(80)};
  right: ${props => props.theme.typography.pxToRem(10)};
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(40)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.primary.dark};
  line-height: ${props => props.theme.typography.pxToRem(20)};
  opacity: 0;
  visibility: hidden;
  width: 100%;
  max-width: ${props => props.theme.typography.pxToRem(370)};
  transition: all 0.3s ease;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  transform: ${props => (props.isPopupVisible ? 'translateY(0)' : 'translateY(10px)')};

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }

  ${props => props.theme.breakpoints.up('sm')} {
    right: ${props => props.theme.typography.pxToRem(85)};
    max-width: ${props => props.theme.typography.pxToRem(300)};
    padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(40)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(15)} !important;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    right: ${props => props.theme.typography.pxToRem(75)};
  }

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -16px;
    background: url(/images/arrow-down.svg) 0 0 no-repeat;
    left: calc(50% - 8px);
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 300px;
    padding: 15px;
    bottom: 90px;

    &::after {
      left: calc(50% - 8px);
    }
  }
`

export const GlobalButtonWrapper = styled.div`
  position: fixed;
  z-index: 1200;
  right: 165px;
  transition: bottom 0.5s ease;
  bottom: 32px
`

export const StyledPopUpCookie = styled.div`
  position: fixed;
  z-index: 1200;
  bottom: ${props => props.theme.typography.pxToRem(80)};
  right: ${props => props.theme.typography.pxToRem(10)};
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(40)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.primary.dark};
  line-height: ${props => props.theme.typography.pxToRem(20)};
  opacity: 1;
  visibility: visible;
  width: 100%;
  max-width: ${props => props.theme.typography.pxToRem(370)};
  transition: all 0.3s ease;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  transform: translateY(0);

  ${props => props.theme.breakpoints.up('sm')} {
    right: ${props => props.theme.typography.pxToRem(85)};
    max-width: ${props => props.theme.typography.pxToRem(300)};
    padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(40)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(15)} !important;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    right: ${props => props.theme.typography.pxToRem(75)};
  }

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -16px;
    background: url(/images/arrow-down.svg) 0 0 no-repeat;
    left: calc(50% - 8px);
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 300px;
    padding: 15px;
    bottom: 90px;

    &::after {
      left: calc(50% - 8px);
    }
  }
`

export const StyledPopUpCookieBtn = styled(IconButton)`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ${props => props.theme.typography.pxToRem(5)};
    right: ${props => props.theme.typography.pxToRem(5)};

    ${props => props.theme.breakpoints.up('sm')} {
        top: ${props => props.theme.typography.pxToRem(10)};
        right: ${props => props.theme.typography.pxToRem(10)};
    }
        
    svg {
        font-size: 24px;
        margin-top: -${props => props.theme.typography.pxToRem(2)};
        fill: #1C1F2A;
    }

    &:hover {
        background-color: #f2f2f2;
    }
`

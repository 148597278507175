import { Alert, AlertTitle } from '@material-ui/lab'
import styled, { css } from 'styled-components'
import { AlertNoticeProps } from "./globalAlert"
import { Container } from "@material-ui/core"
import { Button } from '../button/button'

export const StyledAlert = styled(Alert)<{ $type?: AlertNoticeProps['type']; $display: boolean }>`
  display: ${props => props.$display ? 'flex' : 'none'};
  border-radius: unset;
  align-items: center;
  position: relative;

  .MuiAlert-icon {
    color: #ed8b00;
    align-items: center;
  }

  .MuiAlert-root {
    padding: ${props => props.theme.typography.pxToRem(24)} ${props => props.theme.typography.pxToRem(29)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(32)};
    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(57)} 0 ${props => props.theme.typography.pxToRem(58)};
    }
  }

  .MuiAlert-message {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    line-height: ${props => props.theme.typography.pxToRem(24)};
    padding-bottom: 5px;
    padding-top: 3px;
    width: 100%;

    ${props => props.theme.breakpoints.up('md')} {
      padding-top: 8px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
    
  .MuiAlert-action {
    margin-left: unset
  }

  a {
    color: ${props => props.theme.palette.secondary.main};
  }

  a:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }

  background: none;
  ${props => props.$type === 'dark' && 'color: white;'}
`

export const StyledContainer = styled(Container)<{$type?: AlertNoticeProps['type']}>`
  ${props => props.theme.breakpoints.down('sm') && 'padding: unset;'}

  ${props => props.$type === 'primary' && css`
  color: #1c1f2a;
  background-color: #f2f2f2;
  `}

  ${props => props.$type === 'light' && css`
  color: #1c1f2a;
  background-color: #fff7e6;
  `}

  ${props => props.$type === 'dark' && css`
  color: white;
  background-color: #10131d;
  `}
`

export const StyledButton = styled(Button)<{$color?: string}>`
  font-size: 12px;
  color: white;

  &:hover {
    color: white;
  }
`

export const StyledReadMoreorLessButton = styled(Button)`
  display: block;
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.secondary.main};
  font-weight: 100;
  padding-left: 0;
  padding-top: 0;
  text-align: left;
  padding-bottom: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(13)};
  }
`

export const StyledAlertTitle = styled(AlertTitle)<{ $type?: AlertNoticeProps['type']}>`
  font-weight: 500;
  color: ${props => props.$type === 'dark' ? 'white' : '#0D3C61'};;
  margin-bottom: 0;
  font-size: ${props => props.theme.typography.pxToRem(14)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(12)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledAlertTitleDesktop = styled(AlertTitle)<{ $type?: AlertNoticeProps['type']}>`
  font-weight: 500;
  margin-bottom: 0;
  font-size: ${props => props.theme.typography.pxToRem(15)};
  color: ${props => props.$type === 'dark' ? '#ED8B00' : '#0D3C61'};
`

export const StyledAlertContent = styled.div`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  margin-bottom: ${props => props.theme.typography.pxToRem(5)};

  ${props => `${props.theme.breakpoints.up('xs')} and (orientation: landscape)`} {
    font-size: ${props => props.theme.typography.pxToRem(10)};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(12)};
  }

  ${props => `${props.theme.breakpoints.up('sm')} and (orientation: landscape)`} {
    font-size: ${props => props.theme.typography.pxToRem(10)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledCookieAlertContent = styled.div`
  font-size: ${props => props.theme.typography.pxToRem(13)};
  line-height: 1rem;

  ${props => `${props.theme.breakpoints.up('xs')} and (orientation: landscape)`} {
    font-size: ${props => props.theme.typography.pxToRem(10)};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(12)};
  }

  ${props => `${props.theme.breakpoints.up('sm')} and (orientation: landscape)`} {
    font-size: ${props => props.theme.typography.pxToRem(10)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`
import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { Container, Grid, Link } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import React, { Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useContainerDimensions } from '../../utils/dimension'
import { isNotNull } from '../../utils/nonNull'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { Icon } from '../icon/icon'
import { StyledCreditUnionLogo as CreditUnionLogo } from '../logo/logo'
import { SearchBar } from '../searchBar/SearchBar'
import { StyledBrokenBarIcon, StyledContactUs, StyledIconNavItem, StyledLoginButton } from './StyledNavComponents'
import {
  StyledLogoWrapper,
  StyledNavBuffer,
  StyledNavItemContainer,
  StyledNavigationContainer,
  StyledRightMenuContainer,
} from './StyledNavContainers'

export type PrimaryNavigationProps = {
  secondary?: boolean
  BSB: string
  LoginLink: string
  openMobileMenu?: () => void
}

export type HideOnScrollType = {
  window?: () => Window
  children: React.ReactElement
}

type PrimaryNavigationContextType = {
  searchIsOpen: boolean
  secondary: boolean
  setSearchIsOpen: Dispatch<SetStateAction<boolean>>
}

export const PrimaryNavigationContext = createContext<PrimaryNavigationContextType>({
  secondary: false,
  searchIsOpen: false,
  setSearchIsOpen: () => {},
})

export const PrimaryNavigation: React.FC<PropsWithChildren<PrimaryNavigationProps>> = ({ BSB, LoginLink, secondary = false, openMobileMenu, children }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const isNotTablet = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const useDimensionRef = useRef() as MutableRefObject<HTMLDivElement>
  const { width } = useContainerDimensions(useDimensionRef)
  const [ headerHeight, setHeaderHeight] = useState(0);
  
  // Navbar State
  const [ isNavSticky, setIsNavSticky] = useState(false)
  const [ isOpen, setIsOpen] = useState(false)
  const [ visibleHeader, setVisibleHeader] = useState(true)
  const [ initialOffset, setInitialOffset] = useState<number | null>(null);
  const [ lastScrollY, setLastScrollY ] = useState(0);

  useEffect(() => {
    const alerts = document.querySelector('.cookie-banner-alerts')
    const observer = new ResizeObserver(() => {
      if (alerts) {
        setInitialOffset(alerts.getBoundingClientRect().height)
      }
    })

    if (alerts) {
      observer.observe(alerts)
    }

    return () => {
      observer.disconnect()
    }
  }, []);

  useEffect(() => {
    if (isDesktop && isNotTablet) {
      setHeaderHeight(104);
    } else {
      setHeaderHeight(74);

      if (isMobile) {
        setHeaderHeight(83);
      }
    }
  }, [isDesktop, isNotTablet]);

  const handleNavigation = useCallback((e: Event) => {
    if (initialOffset === null) return
    const window = e.currentTarget as Window

    if (window.scrollY >= initialOffset + headerHeight) {
      setIsNavSticky(true);
      setVisibleHeader(false);
    } else {
      setIsNavSticky(false);
      setVisibleHeader(true);
    }
  }, [isNavSticky, initialOffset, lastScrollY])

  useEffect(() => {
    if (window && initialOffset !== null) {
      window.addEventListener('scroll', handleNavigation)
    }
    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation, initialOffset])

  // Trigger for Navbar Slider
  const threshold = (initialOffset || 0) + headerHeight + 100;
  const trigger = useScrollTrigger({ target: global as unknown as Window, threshold: threshold })

  // Conditioned Icons
  const bsbMenuItem = isDesktop && isNotNull(BSB) && BSB.length>0 && (
    <StyledIconNavItem>
      <StyledContactUs href="/help-and-support/contact-us">Contact Us</StyledContactUs>
    </StyledIconNavItem>
  )

  const loginMenuItem = isNotNull(LoginLink) && LoginLink.length>0 ? (
    <StyledIconNavItem>
      <StyledLoginButton href={LoginLink} target={'_blank'} buttonColor="orange" startIcon={<Icon icon={faLock} />} primary>Login</StyledLoginButton>
    </StyledIconNavItem>
  ) : null

  return (
    <PrimaryNavigationContext.Provider value={{ secondary, searchIsOpen: isOpen, setSearchIsOpen: setIsOpen }}>
      <div ref={useDimensionRef}>
        <StyledNavBuffer $secondary={secondary} $sticky={isNavSticky} $visibleHeader={visibleHeader}/>
        <Slide appear={false} direction="down" in={!trigger}>
          <StyledNavigationContainer $offset={initialOffset} $secondary={secondary} $sticky={isNavSticky} $visibleHeader={visibleHeader} $isOpen={isOpen}>
            <StyledLogoWrapper $sticky={isNavSticky} $containerWidth={width}>
              <Link href={'/'}>
                <CreditUnionLogo notext={isNavSticky} />
              </Link>
            </StyledLogoWrapper>
            {isNotTablet && isOpen == false && (
              <Container maxWidth={'lg'}>
                <Grid container justifyContent={'center'}>
                  <StyledNavItemContainer $sticky={isNavSticky} item lg={10}>
                    {children}
                  </StyledNavItemContainer>
                </Grid>
              </Container>
            )}
            <StyledRightMenuContainer $containerWidth={width}>
              {!isOpen && bsbMenuItem}
              {!isDesktop ? (
                <>
                  <SearchBar />
                  {!isOpen && loginMenuItem}
                </>
              ) : (
                <>
                  {!isOpen && loginMenuItem}
                  <SearchBar sticky={isNavSticky}/>
                </>
              )}
              {!isNotTablet && (
                <StyledBrokenBarIcon aria-label="menu" onClick={openMobileMenu}>
                  <CreditUnionIcon iconCode={'hamburger'} width={29} height={20} />
                </StyledBrokenBarIcon>
              )}
            </StyledRightMenuContainer>
          </StyledNavigationContainer>
        </Slide>
      </div>
    </PrimaryNavigationContext.Provider>
  )
}

import React, {PropsWithChildren, useRef, useState} from 'react'
import { AlertQuery } from '../../pages/api/__generated__/alerts'
import { html } from '../../utils/htmlParser'
import { isNotNull } from '../../utils/nonNull'
import { GlobalAlert } from '../GlobalAlert/globalAlert'
import styled from "styled-components"
import { createPortal } from "react-dom"
import { WebchatButton } from '../webchatButton/WebchatButton'

export type AlertProps = AlertQuery
export type CookieProps = { $sticky?: boolean; $visibleHeader?: boolean; $scrollingUp?: boolean }

export const StyledCookieAlertsContainer = styled.div<CookieProps>`
  width: 100%;
  z-index: 1000;
  transition-property: margin-top, transform, background-color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-in-out !important;
  position: relative;
`

export const CookieBannerAlertsFragment: React.FC<PropsWithChildren<AlertProps>> = ({ globalSet, entries }) => {
  const portal = typeof document !== 'undefined' ? document.querySelector('.global-alerts-container') : null
  const containerRef = useRef<HTMLDivElement | null>(null)

  const alertContainers = (
    <>
      <StyledCookieAlertsContainer ref={containerRef} className={`cookie-banner-alerts`}>
        <>
          {typeof window !== 'undefined' &&
              <>
                {
                  globalSet?.__typename === 'alertsTab_GlobalSet' &&
                  globalSet?.globalAlerts?.[0]?.cookieAlert &&
                    <GlobalAlert
                        cookieAlert={true}
                        readMore={false}
                        buttonText="Accept"
                        hashInfo={globalSet?.globalAlerts?.[0]?.cookieAlert} type={'dark'}
                        severity={'info'}
                    >
                      {html(globalSet?.globalAlerts?.[0]?.cookieAlert)}
                    </GlobalAlert>
                }
              </>
          }
        </>
        {typeof window !== 'undefined' &&
          <>
            {isNotNull(entries) &&
              entries
                .filter((item) => item?.__typename === "alert_default_Entry" && item?.featuredAlerts)
                .slice(0, 2)
                .map((item, index) => (
                  item?.__typename === 'alert_default_Entry' &&
                  item?.status === 'live' &&
                  item.alert?.[0]?.alertContent && (
                    <GlobalAlert
                      key={index}
                      cookieAlert={false}
                      readMore={true}
                      mobileTitle={item?.title as string}
                      hashInfo={item?.alert?.[0]?.alertContent}
                      type={item?.alert?.[0]?.style === 'primary' ? 'primary' : item.alert?.[0]?.style === 'dark' ? 'dark' : 'light'}
                      severity={item?.alert?.[0]?.style === 'primary' ? 'info' : 'warning'}
                    >
                      {html(item.alert?.[0]?.alertContent)}
                    </GlobalAlert>
                  )
                ))}
          </>
        }
      </StyledCookieAlertsContainer>
    </>
  )

  return portal ? createPortal(alertContainers, portal) : alertContainers
}

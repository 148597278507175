import React, { PropsWithChildren, useEffect, useRef, useState } from "react"
import {
  GlobalButtonWrapper,
  QEWrapper,
  StyledGlobalButton,
  StyledPopUp, StyledPopUpCookie, StyledPopUpCookieBtn,
} from "./StyledQuickExit"
import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { Icon } from "../../icon/icon"
const COOKIE_EXPIRY_MS = 3 * 24 * 60 * 60 * 1000

export const QuickExitFragment: React.FC<PropsWithChildren> = () => {
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false)
  const [isHoveringPopup, setIsHoveringPopup] = useState<boolean>(false)
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false)
  const hidePopupTimerRef = useRef<NodeJS.Timeout | null>(null)

  const handleQuickExit = () => {
    window.open("http://www.bom.gov.au/", "_blank", "noopener, noreferrer")
    window.history.replaceState(null, "", "/")
    window.location.replace("https://google.com.au/")
  }

  useEffect(() => {
    const storedData = localStorage.getItem('StyledPopUpCookieHidden')
    if (storedData) {
      const { timestamp } = JSON.parse(storedData)
      if (Date.now() - timestamp < COOKIE_EXPIRY_MS) {
        setCookieVisible(false)
      } else {
        localStorage.removeItem('StyledPopUpCookieHidden')
        setCookieVisible(true)
      }
    } else {
      setCookieVisible(true)
    }
  }, [])

  const handleMouseEnter = () => {
    if (isCookieVisible) return
    if (hidePopupTimerRef.current) {
      clearTimeout(hidePopupTimerRef.current)
      hidePopupTimerRef.current = null
    }
    setPopupVisible(true)
  }

  const handleMouseLeave = () => {
    if (isCookieVisible) return
    if (!isHoveringPopup) {
      hidePopupTimerRef.current = setTimeout(() => {
        setPopupVisible(false)
      }, 3000)
    }
  }

  const handlePopupMouseEnter = () => {
    if (hidePopupTimerRef.current) {
      clearTimeout(hidePopupTimerRef.current)
      hidePopupTimerRef.current = null
    }
    setIsHoveringPopup(true)
  }

  const handlePopupMouseLeave = () => {
    setIsHoveringPopup(false)
    hidePopupTimerRef.current = setTimeout(() => {
      setPopupVisible(false)
    }, 3000)
  }

  const handleCookieClose = () => {
    const data = JSON.stringify({ timestamp: Date.now() })
    localStorage.setItem('StyledPopUpCookieHidden', data)
    setCookieVisible(false)
  }

  return (
    <GlobalButtonWrapper id="quick-exit">
      <QEWrapper>
        {isCookieVisible && (
          <StyledPopUpCookie>
            {`If you need to leave our website quickly and safely, use this quick exit button. `}
            <a
              href="https://www.creditunionsa.com.au/help-and-support/financial-abuse"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about financial abuse
            </a>.
            <StyledPopUpCookieBtn onClick={handleCookieClose}>
              <Icon icon={faXmark} />
            </StyledPopUpCookieBtn>
          </StyledPopUpCookie>
        )}
        <StyledPopUp
          isPopupVisible={isPopupVisible}
          className={isPopupVisible ? "show" : "hide"}
          onMouseEnter={handlePopupMouseEnter}
          onMouseLeave={handlePopupMouseLeave}
        >
          {`If you need to leave our website quickly and safely, use this quick exit button. `}
          <a
            href="https://www.creditunionsa.com.au/help-and-support/financial-abuse"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about financial abuse
          </a>.
        </StyledPopUp>
        <StyledGlobalButton
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleQuickExit}
        >
          {`Quick exit`}
        </StyledGlobalButton>
      </QEWrapper>
    </GlobalButtonWrapper>
  )
}

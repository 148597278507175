import styled from 'styled-components'
import { Box, Modal } from "@material-ui/core";
import { Button } from "../button/button"
import { Icon } from '../icon/icon'
import {StyledAlertTitleDesktop} from "../GlobalAlert/StyledGlobalAlert";

export const StyledModalAlertContentWrapper = styled(Box)`
    background: url('/images/popup-bg.png') no-repeat bottom right #fff;
    background-size: ${props => props.theme.typography.pxToRem(50)};;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: ${props => props.theme.typography.pxToRem(1050)};
    width: calc(100% - ${props => props.theme.typography.pxToRem(70)});
    padding: ${props => props.theme.typography.pxToRem(50)} ${props => props.theme.typography.pxToRem(15)};
    max-height: calc(100% - ${props => props.theme.typography.pxToRem(70)});
    overflow-y: auto;
    border: none;
    outline: none;
    min-width: ${props => props.theme.typography.pxToRem(320)};

    ${props => props.theme.breakpoints.up('sm')} {
        padding: ${props => props.theme.typography.pxToRem(60)} ${props => props.theme.typography.pxToRem(35)};
        background-size: ${props => props.theme.typography.pxToRem(75)};;
    }

    ${props => props.theme.breakpoints.up('md')} {
        padding: ${props => props.theme.typography.pxToRem(90)} ${props => props.theme.typography.pxToRem(85)};
        background-size: ${props => props.theme.typography.pxToRem(75)};;
    }
`

export const StyledModal = styled(Modal)`
    background: rgba(0, 0, 0, 0.8);
`

export const StyledAlertContent = styled.div`
    > div {
        margin-bottom: ${props => props.theme.typography.pxToRem(10)};
        
        .MuiAlert-action {
            display: none;
        }
        
        .MuiAlert-root {
            padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(10)};
            align-items: flex-start;

            ${props => props.theme.breakpoints.up('sm')} {
              padding: ${props => props.theme.typography.pxToRem(20)} ${props => props.theme.typography.pxToRem(20)};
            }

            ${props => props.theme.breakpoints.up('md')} {
                padding: ${props => props.theme.typography.pxToRem(20)} ${props => props.theme.typography.pxToRem(30)};
            }

            .MuiAlert-icon {
                margin-right: ${props => props.theme.typography.pxToRem(10)};
                padding-top: ${props => props.theme.typography.pxToRem(3)};

                ${props => props.theme.breakpoints.up('sm')} {
                  padding-top: ${props => props.theme.typography.pxToRem(3)};
                }

                ${props => props.theme.breakpoints.up('md')} {
                    padding-top: ${props => props.theme.typography.pxToRem(10)};
                }

                ${props => props.theme.breakpoints.up('lg')} {
                    padding-top: ${props => props.theme.typography.pxToRem(5)};
                }

                svg {
                    width: ${props => props.theme.typography.pxToRem(16)};
                    height: ${props => props.theme.typography.pxToRem(16)};
                    
                    ${props => props.theme.breakpoints.up('sm')} {
                        width: ${props => props.theme.typography.pxToRem(20)};
                        height: ${props => props.theme.typography.pxToRem(20)};    
                    }
                }
            }
        }
        
        .MuiAlert-message {
          font-size: ${props => props.theme.typography.pxToRem(13)};
          line-height: ${props => props.theme.typography.pxToRem(18)};
          padding-bottom: 5px;
          padding-top: 3px;
          width: 100%;
      
          ${props => props.theme.breakpoints.up('sm')} {
            font-size: ${props => props.theme.typography.pxToRem(15)};
            line-height: ${props => props.theme.typography.pxToRem(24)};
          }
      
          ${props => props.theme.breakpoints.up('md')} {
            padding-top: 8px;
          }
      
          p {
            margin-top: 0;
            margin-bottom: 0;
            font-size: ${props => props.theme.typography.pxToRem(13)};
      
            ${props => props.theme.breakpoints.up('sm')} {
                font-size: ${props => props.theme.typography.pxToRem(15)};
            }
          }
            
          ${StyledAlertTitleDesktop} {
            font-size: ${props => props.theme.typography.pxToRem(13)};

            ${props => props.theme.breakpoints.up('sm')} {
                font-size: ${props => props.theme.typography.pxToRem(15)};
            }
          }
        }
        
        &:last-child {
            margin-bottom: 0;
        }
    }
`

export const StyledAlertH2 = styled.h2`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  line-height: ${props => props.theme.typography.pxToRem(30)};
  font-weight: 500;
  color: #1C1F2A;
  padding-left: ${props => props.theme.typography.pxToRem(15)};
  padding-right: ${props => props.theme.typography.pxToRem(15)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(32)};
    line-height: ${props => props.theme.typography.pxToRem(45)};
    padding-left: ${props => props.theme.typography.pxToRem(20)};
    padding-right: ${props => props.theme.typography.pxToRem(20)};
    margin-top: 0;
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-left: ${props => props.theme.typography.pxToRem(30)};
    padding-right: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledIconButton = styled.button`
    position: absolute;
    border-radius: 50%;
    top: ${props => props.theme.typography.pxToRem(20)};
    right: ${props => props.theme.typography.pxToRem(20)};
    transition: all 0.3s ease-in-out;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;

    &:hover {
        svg {
            fill: #f35a21;
        }
    }

    ${props => props.theme.breakpoints.up('sm')} {
        top: ${props => props.theme.typography.pxToRem(30)};
        right: ${props => props.theme.typography.pxToRem(30)};
    }
`

export const StyledIcon = styled(Icon)`
    font-size: ${props => props.theme.typography.pxToRem(14)};
    color: ${props => props.theme.palette.primary.dark};
    transition: all 0.2s ease;
    width: ${props => props.theme.typography.pxToRem(22)};
    height: ${props => props.theme.typography.pxToRem(22)};

    ${props => props.theme.breakpoints.up('sm')} {
        font-size: ${props => props.theme.typography.pxToRem(20)};
        width: ${props => props.theme.typography.pxToRem(30)};
        height: ${props => props.theme.typography.pxToRem(30)};
    }
`
